.home{
	position:relative;
	width: 100%;
	overflow: hidden;
	height: 100vh;
	video{
		position: absolute;
		top: 0;
		z-index: -1;
		width: 300%;
	}

	.hero{
		position: relative;
		margin-top: 25%;

		h1{
			font-size: 3.5rem;
			color: white;
		}

		h2{
			color: white;
		}

		.section{
			border-top:1px solid white;
		}

		button{
			padding: 10px;
			background-color: transparent;
			border: 1px solid white;
			color: white;
			font-size: 1.5rem;
			font-weight: 700;
		}
	}
}


@media(min-width: 1200px){
	.home{
		max-height: 56rem;

		video{
			width: 100%;
		}
	}
}