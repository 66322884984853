.wv-nav{
	position: fixed;
	font-size: 1.5rem;
	border: 1px solid #efefef;
	backdrop-filter: blur(10px);
	background-color:rgba(355,355,355,.95);
	z-index: 10;

	img{
		max-width: 12rem;
		width: 100%;
		height: auto;
	}

	i{
		font-size: 2rem;
	}

	button{
		border: none;
		background-color:transparent;
		padding:0;
	}

	a{
		color: black;
		font-weight: 700;
		padding:1rem 0;
		text-decoration: none;
		&:hover{
			color:#3a9aa9;
		}
	}

	.book-btn{
		color: #3a9aa9;
		border: 1px solid;
		border-color: #3a9aa9;
		padding:1rem;
		&:hover{
			background-color: #3a9aa9;
			color: white;
		}
	}

	.item{
		margin: .8rem 0;
	}

	.item:last-child{
		margin-top: 1.6rem;
	}

}

@media (min-width: 1200px){
	.wv-nav{
		background-color:rgba(355,355,355,.8);
		font-size: .85rem;
	}

	.item{
		margin: 0 !important;
	}
}

@media (min-width: 1460px){
	.wv-nav{
		font-size: 1rem;
	}
}